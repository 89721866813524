import React from "react";

function Products({
  products,
  total,
  handlePriceChange,
  handleQuantityChange,
}) {
  return (
    <div className="agent_products">
      <h2>Produse</h2>
      {products.map((product, index) => (
        <div key={product._id} className="agent_products_item">
          <span>{product.name}</span>
          <div className="agent_products_price">
            <input
              type="number"
              value={product.price}
              onChange={(e) => handlePriceChange(index, e.target.value)}
              className="agent_price-input"
            />
            lei
          </div>
          <button
            onClick={() => handleQuantityChange(index, product.quantity - 1)}
          >
            -
          </button>
          <input
            type="number"
            value={product.quantity}
            onChange={(e) => handleQuantityChange(index, e.target.value)}
            className="agent_quantity-input"
          />
          <button
            onClick={() => handleQuantityChange(index, product.quantity + 1)}
          >
            +
          </button>
        </div>
      ))}
      <div className="agent_total">
        <h3>Total: {total} lei</h3>
      </div>
    </div>
  );
}

export default Products;
