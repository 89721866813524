import React from "react";

function Factura({factura, setFactura}) {
  return (
    <div className="agent_factura-section">
      <label>
        <input
          type="checkbox"
          checked={factura}
          onChange={() => setFactura(!factura)}
        />
        Cu factură
      </label>
    </div>
  );
}

export default Factura;
