import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../constants";
import "../Workers.scss";
import Accordion from "./Accordion";
import OrderCard from "./OrderCard";
import ShopSelector from "../Agent/ShopSelector"; // Reuse ShopSelector from Agent.js

function History() {
  const [orders, setOrders] = useState([]);
  const [shops, setShops] = useState([]);
  const [originalShops, setOriginalShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [isShopListOpen, setIsShopListOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAccordions, setOpenAccordions] = useState({});
  const [searchTerm, setSearchTerm] = useState(""); // New state for search input
  const [selectedCompany, setSelectedCompany] = useState("all");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ordersResponse, shopsResponse] = await Promise.all([
          axios.get(`${BASE_URL}/agentOrders`),
          axios.get(`${BASE_URL}/shops`),
        ]);

        const filteredOrders =
          selectedCompany === "all"
            ? ordersResponse.data
            : ordersResponse.data.filter(
                (order) => order.company === selectedCompany
              );

        setOrders(filteredOrders);
        setOriginalShops(shopsResponse.data);
        setShops(shopsResponse.data); // Initially, all shops are displayed

        const groupedOrders = groupOrdersByDate(filteredOrders);
        const firstMonthYear = Object.keys(groupedOrders)[0];
        const firstDay = Object.keys(groupedOrders[firstMonthYear])[0];
        setOpenAccordions({
          [firstMonthYear]: {
            [firstDay]: true,
          },
        });
      } catch (error) {
        setError("Error fetching orders");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCompany]); // Trigger effect when selectedCompany changes

  // Function to handle shop selection
  const handleShopSelect = (shopId) => {
    setSelectedShop(shopId);
    filterOrdersByShop(shopId);
    setIsShopListOpen(false);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    // Filter shops based on search term
    const filteredShops = originalShops.filter((shop) =>
      shop.name.toLowerCase().includes(searchTerm)
    );
    setShops(filteredShops);
  };

  // Group orders by date
  // Group orders by date
const groupOrdersByDate = (orders) => {
  const groupedOrders = {};

  orders.forEach((order) => {
    const orderDate = new Date(order.orderDate);
    const day = orderDate.toLocaleDateString();
    const monthYear = `${orderDate.toLocaleString("default", { month: "long" })} ${orderDate.getFullYear()}`;

    if (!groupedOrders[monthYear]) {
      groupedOrders[monthYear] = {};
    }

    if (!groupedOrders[monthYear][day]) {
      groupedOrders[monthYear][day] = [];
    }

    groupedOrders[monthYear][day].push(order);
  });

  // Reverse the month-year and day keys so the newest ones come first
  const reversedGroupedOrders = {};
  const monthYears = Object.keys(groupedOrders).reverse(); // Reverse months and years

  monthYears.forEach((monthYear) => {
    const days = Object.keys(groupedOrders[monthYear]).reverse(); // Reverse days for each month-year
    reversedGroupedOrders[monthYear] = {};
    
    days.forEach((day) => {
      reversedGroupedOrders[monthYear][day] = groupedOrders[monthYear][day];
    });
  });

  return reversedGroupedOrders;
};


  // Function to filter orders by selected shop
  const filterOrdersByShop = (shopId) => {
    if (shopId === "all") {
      setOrders(orders); // Show all orders if "all" is selected
    } else if (shopId) {
      setOrders(orders.filter((order) => order.shopId === shopId)); // Filter orders by selected shop
    }
  };

  // Toggle accordion for grouped orders
  const toggleAccordion = (monthYear, day) => {
    setOpenAccordions((prevState) => ({
      ...prevState,
      [monthYear]: {
        ...prevState[monthYear],
        [day]: !prevState[monthYear]?.[day],
      },
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const groupedOrders = groupOrdersByDate(orders);

  return (
    <div className="history">
      <h1>Istoria comenzilor</h1>
      <div>
        <label htmlFor="company-selector">Selectează Compania:</label>
        <select
          className="company-selector"
          value={selectedCompany}
          onChange={(e) => setSelectedCompany(e.target.value)}
        >
          <option value="all">Toate</option>
          <option value="TriZooGrup">TriZooGrup</option>
          <option value="ZooKiWi">ZooKiWi</option>
        </select>
      </div>

      {/* Shop Selector with Search */}
      <ShopSelector
        shops={shops}
        selectedShop={selectedShop}
        isShopListOpen={isShopListOpen}
        setIsShopListOpen={setIsShopListOpen}
        handleShopSelect={handleShopSelect}
        handleSearchChange={handleSearchChange} // Pass search handler
        includeAllOption={true} // Include "All" option
      />

      <div className="history_orders">
        {Object.keys(groupedOrders).map((monthYear) => (
          <div key={monthYear} className="history_month">
            <h2>{monthYear}</h2>
            {Object.keys(groupedOrders[monthYear]).map((day) => (
              <Accordion
                key={day}
                monthYear={monthYear}
                day={day}
                toggleAccordion={toggleAccordion}
                openAccordions={openAccordions}
              >
                {groupedOrders[monthYear][day].map((order) => (
                  <OrderCard key={order.orderId} order={order} />
                ))}
              </Accordion>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default History;
