import { LOCALES } from "../locales";

const translationKeys = {
  [LOCALES.ROMANIAN]: {
    ynformation:
      "Livrarea gratuită pentru orice comandă, dacă comanda conține așternutul KiWi.",
    homePage: "Pagina principală",
    deliveryAndPayment: "Livrare și achitare",
    cats: "Pisici",
    dogs: "Câini",
    productsTitleHome: "Produsele noastre",
    productPageButtonHome: "pagina produsului",
    lei: "lei",
    theProduct: "Produsul",
    wasAddedToCart: "a fost adăugat in coș",
    addToCartButton: "Adaugă în coș",
    emptyCart: "Coșul este gol",
    nextCart: "Mai departe",
    sum: "Suma",
    total: "Total",
    totalOfProducts: "Suma produselor",
    shipping: "Livrare",
    checkboxText:
      "Prin trimiterea comenzii accept termeni de politica de confidențialitate",
    name: "Nume",
    phone: "Telefon",
    email: "E-mail",
    city: "Orașul",
    street: "Strada, blocul",
    building: "Casa/blocul",
    entrance: "Scara",
    floor: "Etajul",
    apartment: "Apartamentul",
    comment: "Comentariu",
    cash: "Achitare în cash (numerar)",
    card: "Achitare cu cardul la livrare",
    cart: "Coș",
    personalData: "Date personale",
    address: "Adresa",
    payment: "Achitare",
    continueShopping: "Continuă cumpărăturile",
    goToCart: "Treci în coș",
    orderPlaced: "Comanda a fost primită",
    orderPlacedNR: "Numărul comenzii:",
    finish: "Finisează",
    contact: "Contacte",
    delivery: "Livrare",
    delivery1: "Prețul minim: 150 lei (+ livrarea 40 lei)",
    delivery2: "Livrarea gratuită pentru orice comandă",
    sellingOnly: "Se vinde doar în set din",
    unit: "buc.",
    promocode: "Cod promoțional",
    discount: "Reducere",
    apply: "Aplică",
    cancel: "Anulează",
    invalid: "Acest cod promoțional nu e valid",
    infodeliverytitle1: "Condițiile de livrare",
    infodeliverytitle2: "Livrarea prin Chișinău:",
    infodeliveryfloor:
      "Livrarea se efectuează pînă la ușile casei D-stră, dar în cazul cînd comanda necesită livrată mai sus de etajul 3 (în blocurile fără ascensor), atunci va trebui de achitat adăugător 30 lei curierului pentru ridicarea comenzei la etajul necesar.",
    infodelivery1:
      "este gratuită pentru orice cumpărătură, dacă comanda conține așternutul KiWi.",
    infodelivery2:
      "se efectueză de luni pînă sîmbătă între orele 10:00 și 19:00",
    infodeliveryNew3:
      "dacă comanda nu conține așternutul KiWi, atunci comanda minimă e de la 300 lei, și livrarea la fel, e gratuită.",
    infodelivery3:
      "comenzile primite pînă la orele 10:00 a zilei curente, se livrează în aceiași zi, iar comenzile primite după orele 10:00 se livrează ori în aceiași zi, ori în ziua următoare.",
    infodelivery4:
      "Este important să înțelegeți faptul că noi nu tot timpul reușim să efectuăm livrarea la ora indicată în comentariu, deoarece se fac multe comenzi la aceiași oră în diferite regiuni ale orașului. Deaceea curierul nostru va lua legătură cu D-stră preventiv și se va stabili o oră a livrării reciproc acceptabilă.",
    infodeliverytitle3:
      "Livrarea prin suburbiile Chișinăului, la fel și în regiunea Aeroport și în or. Ialoveni:",
    infodelivery5:
      "se efectuează numai pentru comenzile mai mari de 500 lei și la fel este absolut gratuită.",
    infodelivery6:
      "Livrarea către alte raioane trebuie discutată aparte și numai pentru comenzile mari.",
    infopaymenttitle1: "Achitarea",
    infopayment1:
      "Achitarea se efectuează în numerar ori prin intermediul cardului bancar curierului după recepționarea comenzei. ",

    infoproductstitle1:
      "Rugăm sa atrageți atenția că așternutul igienic se vinde numai cu seturi:",
    infoproducts1: "din 5 pachete pentru cele mici (2kg sau 3kg)",
    infoproducts2: "din 2 pachete pentru mari (7kg)",
    orderConfirmation: "Sunați înapoi pentru confirmare",
    catProducts: "Produse pentru pisici",
    dogProducts: "Produse pentru câini",
    all: "Toate",
    theProducts: "produsele",
    food: "Hrană",
    dry: "Uscată",
    wet: "Umedă",
    filler: "Așternut",
    accessory: "Accesorii",
    hygienic: "igienic",
    sort1: "Sortare (Mod implicit)",
    sort2: "Preț de la mic la Mare",
    sort3: "Preț de la Mare la mic",
    comingSoon: "În curând va apărea",
    announcement1:
      "🔔 ATENȚIE! La moment nu efectuăm livrări!!!",
    announcement2:
      "Comenzile vor fi prelucrate începând cu 1 februarie!",
  },
};

export default translationKeys;
