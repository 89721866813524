import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { buildingComplete, streetComplete } from "../../../features/cartFields";
import {
  updateStreet,
  updateBuilding,
  updateEntrance,
  updateFloor,
  updateApartment,
  updateComment,
} from "../../../features/dataSlice";
import { LOCALES, translate } from "../../../i18n";

function Address() {
  const { street, building, entrance, floor, apartment, comment } = useSelector(
    (state) => state.data.address
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (street.length > 0) {
      dispatch(streetComplete(true));
    } else {
      dispatch(streetComplete(false));
    }
    if (building.length > 0) {
      dispatch(buildingComplete(true));
    } else {
      dispatch(buildingComplete(false));
    }
  }, []);

  const handleStreetChange = (e) => {
    dispatch(updateStreet(e));
    if (e.length > 0) {
      dispatch(streetComplete(true));
    } else {
      dispatch(streetComplete(false));
    }
  };
  const handleBuildingChange = (e) => {
    dispatch(updateBuilding(e));
    if (e.length > 0) {
      dispatch(buildingComplete(true));
    } else {
      dispatch(buildingComplete(false));
    }
  };
  const handleEntranceChange = (e) => {
    dispatch(updateEntrance(e));
  };
  const handleFloorChange = (e) => {
    dispatch(updateFloor(e));
  };
  const handleApartmentChange = (e) => {
    dispatch(updateApartment(e));
  };
  const handleCommentChange = (e) => {
    dispatch(updateComment(e));
  };
  const language = useSelector((state) => state.language);
  //  orasul se salveaza sub strada iar strada si blocul sub bloc
  return (
    <div className="cart_address cart_main blackboard">
      <section className="cart_address_flex">
        <article className="cart_address_field cart_address_field">
          <label className="required" htmlFor="street">
            {translate("city")}:
          </label>
          <input
            value={street}
            className="required"
            tabIndex={1}
            type="text"
            name="street"
            id="street"
            maxLength={24}
            onChange={(e) => {
              handleStreetChange(e.target.value);
            }}
          />
        </article>
        <article className="cart_address_field cart_address_field--big">
          <label
            className="required cart_address_field--building_label"
            htmlFor="building"
          >
            <span>{translate("street")}</span>
          </label>
          <input
            value={building}
            className="required"
            tabIndex={2}
            type="text"
            name="building"
            id="building"
            maxLength={64}
            onChange={(e) => {
              handleBuildingChange(e.target.value);
            }}
          />
        </article>
      </section>

      <section className="cart_address_flex">
        <article className="cart_address_field">
          <label htmlFor="entrance" maxLength={15}>
            {translate("entrance")}
          </label>
          <input
            value={entrance}
            tabIndex={3}
            type="text"
            name="entrance"
            id="entrance"
            maxLength={15}
            onChange={(e) => {
              handleEntranceChange(e.target.value);
            }}
          />
        </article>
        <article className="cart_address_field">
          <label htmlFor="floor">{translate("floor")}</label>
          <input
            value={floor}
            tabIndex={4}
            type="text"
            name="floor"
            id="floor"
            maxLength={10}
            onChange={(e) => {
              handleFloorChange(e.target.value);
            }}
          />
        </article>
        <article className="cart_address_field">
          <label htmlFor="apartment">{translate("apartment")}</label>
          <input
            value={apartment}
            tabIndex={5}
            type="text"
            name="apartment"
            id="apartment"
            maxLength={10}
            onChange={(e) => {
              handleApartmentChange(e.target.value);
            }}
          />
        </article>
      </section>
      <section className="cart_address_flex">
        <article className="cart_address_field cart_address_field--humongous">
          <label htmlFor="comment">{translate("comment")}:</label>
          <textarea
            value={comment}
            placeholder={
              language === LOCALES.ROMANIAN
                ? "La dorință. (Timpul livrării, alte detalii, etc...)"
                : "По желанию. (Время доставки, другие уточнения, и т.д.)"
            }
            tabIndex={6}
            name="comment"
            id="comment"
            maxLength={514}
            onChange={(e) => {
              handleCommentChange(e.target.value);
            }}
          />
        </article>
      </section>
    </div>
  );
}

export default Address;
