import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../constants";
import "./Statistics.scss";

function Statistics() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statistics, setStatistics] = useState(null);

  const handleGenerate = async () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates!");
      return;
    }

    try {
      const response = await axios.get(
        `${BASE_URL}/statistics?start=${startDate}&end=${endDate}`
      );
      setStatistics(response.data);
    } catch (error) {
      console.error("Error fetching statistics:", error);
      alert("Failed to fetch statistics.");
    }
  };

  return (
    <div className="statistics">
      <h1>Statistica Comenzi</h1>
      <div className="date-selection">
        <label>
          De la:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          Până la:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <button onClick={handleGenerate}>Generează Statistică</button>
      </div>

      {statistics && (
        <div className="statistics-results">
          <h2>Rezultate:</h2>
          <p>Total Comenzi: {statistics.totalOrders}</p>
          <p>Total Venituri: {statistics.totalRevenue} lei</p>
          <p>TriZooGrup: {statistics.revenueByCompany.TriZooGrup} lei</p>
          <p>ZooKiWi: {statistics.revenueByCompany.ZooKiWi} lei</p>
          <h3>Produse:</h3>
          <ul>
            {statistics.products.map((product) => (
              <li key={product._id}>
                {product.name} - {product.totalQuantity} unitați -{" "}
                {product.totalRevenue} lei
              </li>
            ))}
          </ul>
          <p>
            Produs Cel Mai Comandat: {statistics.topProduct.name} (
            {statistics.topProduct.totalQuantity} unitați)
          </p>
          <p>
            Valoare Medie Comandă: {statistics.averageOrderValue.toFixed(1)} lei
          </p>
        </div>
      )}
    </div>
  );
}

export default Statistics;
