import React, { useState } from "react";
import axios from "axios";
import "./AddShopForm.scss";
import { BASE_URL } from "../../../../constants";

const AddShopForm = () => {
  const [shopName, setShopName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");
    setSuccess("");

    if (!shopName.trim()) {
      setError("Shop name is required");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/shops`, {
        name: shopName,
      });

      setShopName("");
      setSuccess("Magazin adăugat cu succes!");
    } catch (err) {
      setError("Failed to add shop. Please try again.");
      console.error("Eroare adăugare magazin:", err);
    }
  };

  return (
    <div className="add-shop-container">
      <form className="add-shop-form" onSubmit={handleSubmit}>
        <h2 className="form-title">Adăugarea magazine</h2>

        <div className="form-group">
          <label htmlFor="shopName" className="form-label">
            Numele Magazinului
          </label>
          <input
            type="text"
            id="shopName"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
            className="form-input"
            placeholder="..."
            required
          />
        </div>

        {error && <div className="form-error">{error}</div>}

        {success && <div className="form-success">{success}</div>}

        <div className="form-actions">
          <button type="submit" className="submit-button">
            Adaugă Magazin
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddShopForm;
