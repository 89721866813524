import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure to install axios
import "../Workers.scss"; // Import the SCSS file
import { BASE_URL } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import ShopSelector from "./ShopSelector";
import Comment from "./Comment";
import Factura from "./Factura";
import Products from "./Products";

const AgentPage = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [shops, setShops] = useState([]);
  const [originalShops, setOriginalShops] = useState([]); // New state for original shops
  const [selectedShop, setSelectedShop] = useState(null);
  const [products, setProducts] = useState([]);
  const [defaultPrices, setDefaultPrices] = useState([]); // New state to store default prices
  const [comment, setComment] = useState("");
  const [factura, setFactura] = useState(false);
  const [isShopListOpen, setIsShopListOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("TriZooGrup");

  const handleNavigateToHistory = () => {
    navigate("/history");
  };

  useEffect(() => {
    const newTotal = products.reduce((acc, product) => {
      if (product.quantity > 0) {
        return acc + product.price * product.quantity;
      }
      return acc;
    }, 0);
    setTotal(newTotal);
  }, [products]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch shops and products from backend
        const [shopsRes, productsRes] = await Promise.all([
          axios.get(`${BASE_URL}/shops`),
          axios.get(`${BASE_URL}/items`),
        ]);

        setShops(shopsRes.data);
        setOriginalShops(shopsRes.data); // Store original shops

        // Store both products and default prices when products are fetched
        const fetchedProducts = productsRes.data.map((p) => ({
          ...p,
          quantity: 0,
        }));
        setProducts(fetchedProducts);

        // Save the default prices separately
        setDefaultPrices(
          fetchedProducts.map((p) => ({
            productId: p._id,
            defaultPrice: p.price,
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleShopSelect = async (shopId) => {
    setSelectedShop(shopId);
    setIsShopListOpen(false);

    try {
      const response = await axios.get(
        `${BASE_URL}/shopProducts/prices/${shopId}`
      );
      const shopProducts = response.data;

      const updatedProducts = products.map((product) => {
        const shopProduct = shopProducts.find(
          (p) => p.productId === product._id
        );

        // Find the default price for the current product
        const defaultPrice = defaultPrices.find(
          (p) => p.productId === product._id
        )?.defaultPrice;

        return {
          ...product,
          price:
            shopProduct && shopProduct.price
              ? shopProduct.price
              : defaultPrice || product.price, // Use default price if shopProduct price is not available
        };
      });

      setProducts(updatedProducts);
    } catch (error) {
      console.error("Error fetching shop-specific prices:", error);
    }
  };

  const handleQuantityChange = (index, value) => {
    if (value >= 0) {
      const newProducts = [...products];
      newProducts[index].quantity = value;
      setProducts(newProducts);
    } else {
      const newProducts = [...products];
      newProducts[index].quantity = 0;
      setProducts(newProducts);
    }
  };

  const handlePriceChange = (index, value) => {
    const newProducts = [...products];
    newProducts[index].price = value;
    setProducts(newProducts);
  };

  const handleProductPrices = async (products, shopId) => {
    try {
      for (const product of products) {
        const { productId, price } = product;

        // Send a request to update the product price in the shopProducts collection
        await axios.post(`${BASE_URL}/shopProducts/updatePrice`, {
          shopId,
          productId,
          price,
        });
      }
    } catch (error) {
      console.error("Error updating product prices:", error);
    }
  };

  const handleSendOrder = async () => {
    // Check if any products have a quantity greater than zero
    const hasProducts = products.some((product) => product.quantity > 0);

    if (!hasProducts) {
      alert("Please add at least one product to your order before sending.");
      return; // Prevent the order from being sent
    }

    const selectedShopData = shops.find((shop) => shop._id === selectedShop);
    const order = {
      shopId: selectedShop,
      shopName: selectedShopData?.name || "Unknown Shop",
      company: selectedCompany,
      products: products
        .filter((product) => product.quantity > 0)
        .map((product) => ({
          productId: product._id,
          name: product.name,
          price: parseFloat(product.price) || 0,
          quantity: product.quantity,
        })),
      comment,
      factura,
    };

    try {
      // Send the order
      await axios.post(`${BASE_URL}/agentOrders`, order);

      // Handle product prices in shopProducts
      await handleProductPrices(order.products, selectedShop);

      // Reset prices to default after the order is sent
      const updatedProducts = products.map((product) => {
        return {
          ...product,
          price: product.price, // Assuming defaultPrice is available in the product object
          quantity: 0,
        };
      });
      setProducts(updatedProducts);

      // Reset form
      setSelectedShop(null);
      setComment("");
      setSelectedCompany("TriZooGrup"); // Reset company
      setFactura(false);
      alert("Comanda trimisă cu succes!");
    } catch (error) {
      console.error("Eroare de trimitere a comenzii:", error);
    }
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm === "") {
      // If the search term is empty, reset the shops to the original list
      setShops(originalShops);
    } else {
      const filteredShops = originalShops.filter((shop) =>
        shop.name.toLowerCase().includes(searchTerm)
      );
      setShops(filteredShops);
    }
  };

  return (
    <div className="agent">
      <div className="agent_flex">
        <div className="agent_company">
          <label htmlFor="company-selector">Selectează Compania:</label>
          <select
            className="company-selector"
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
          >
            <option value="TriZooGrup">TriZooGrup</option>
            <option value="ZooKiWi">ZooKiWi</option>
          </select>
        </div>

        <ShopSelector
          shops={shops}
          originalShops={originalShops}
          selectedShop={selectedShop}
          isShopListOpen={isShopListOpen}
          setIsShopListOpen={setIsShopListOpen}
          handleShopSelect={handleShopSelect}
          handleSearchChange={handleSearchChange}
        />

        <div className="agent_flex_right">
          <Products
            products={products}
            total={total}
            handlePriceChange={handlePriceChange}
            handleQuantityChange={handleQuantityChange}
          />

          <Comment comment={comment} setComment={setComment} />

          <Factura factura={factura} setFactura={setFactura} />

          <button onClick={handleSendOrder} className="agent_send-order-btn">
            Trimite Comanda
          </button>
        </div>
      </div>
      <button onClick={handleNavigateToHistory} className="history-btn">
        Vezi Istoricul Comenzilor
      </button>
    </div>
  );
};

export default AgentPage;
