import React from "react";

function Accordion({
  children,
  monthYear,
  day,
  toggleAccordion,
  openAccordions,
}) {
  return (
    <div className="history_day">
      <header
        className="history_accordion-header"
        onClick={() => toggleAccordion(monthYear, day)}
      >
        <h3>{day}</h3>
        <button className="toggle-btn">
          {openAccordions[monthYear]?.[day] ? "Închide" : "Deschide"}
        </button>
      </header>
      <div
        className={`history_accordion-container ${
          openAccordions[monthYear]?.[day] ? "history_accordion-container--open" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export default Accordion;
