import React from "react";
import Order from "./Order";

function OrderGroup({
  date,
  orders,
  isOpen,
  toggleAccordion,
  handleCheckboxChange,
  handleCompleteOrder,
}) {
  return (
    <section className="operator_order-group">
      <header
        className="operator_order-group-header"
        onClick={toggleAccordion}
      >
        <h2>{date}</h2>
        <button className="toggle-btn">
          {isOpen ? "Închide" : "Deschide"}
        </button>
      </header>
      <div
        className={`operator_accordion-container ${
          isOpen ? "operator_accordion-container--open" : ""
        }`}
      >
        {orders.map((order) => (
          <Order
            key={order.orderId}
            order={order}
            handleCheckboxChange={handleCheckboxChange}
            handleCompleteOrder={handleCompleteOrder}
          />
        ))}
      </div>
    </section>
  );
}

export default OrderGroup;
