import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import OrderGroup from "./OrderGroup";
import ShopSelector from "../Agent/ShopSelector"; // Reuse ShopSelector from Agent.js

function Operator() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [shops, setShops] = useState([]);
  const [originalShops, setOriginalShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("all"); // New state for company filter
  const [isShopListOpen, setIsShopListOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAccordion, setOpenAccordion] = useState(0);

  const handleNavigateToHistory = () => {
    navigate("/history");
  };
  const handleNavigateToAddShop = () => {
    navigate("/addshop");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ordersResponse, shopsResponse] = await Promise.all([
          axios.get(`${BASE_URL}/agentOrders`),
          axios.get(`${BASE_URL}/shops`),
        ]);

        const uncompletedOrders = ordersResponse.data.filter(
          (order) => !order.isCompleted
        );
        setOrders(uncompletedOrders);
        setFilteredOrders(uncompletedOrders);

        setShops(shopsResponse.data);
        setOriginalShops(shopsResponse.data);
      } catch (error) {
        setError("Error fetching data");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const groupOrdersByDate = (orders) => {
    return orders.reduce((acc, order) => {
      const date = new Date(order.orderDate).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(order);
      return acc;
    }, {});
  };

  const toggleAccordion = (index) => {
    setOpenAccordion((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const handleCheckboxChange = async (orderId, field) => {
    const updatedOrders = orders.map((order) =>
      order.orderId === orderId ? { ...order, [field]: !order[field] } : order
    );

    setOrders(updatedOrders);

    // Update filteredOrders to reflect changes immediately
    const updatedFilteredOrders = filteredOrders.map((order) =>
      order.orderId === orderId ? { ...order, [field]: !order[field] } : order
    );

    setFilteredOrders(updatedFilteredOrders); // Update the filtered orders as well

    const updatedOrder = updatedOrders.find(
      (order) => order.orderId === orderId
    );

    const updatePayload = {
      isPaid: updatedOrder.paid,
      isDelivered: updatedOrder.delivered,
    };

    try {
      await axios.put(`${BASE_URL}/agentOrders/${orderId}`, updatePayload);
      console.log("Order status updated successfully!");
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleCompleteOrder = async (orderId) => {
    try {
      await axios.put(`${BASE_URL}/agentOrders/${orderId}`, {
        isCompleted: true,
      });
      console.log("Order completed!");

      // Filter out the completed order immediately
      const updatedOrders = orders.filter((order) => order.orderId !== orderId);
      setOrders(updatedOrders);

      // Update filtered orders to reflect the completed status
      const updatedFilteredOrders = filteredOrders.filter(
        (order) => order.orderId !== orderId
      );
      setFilteredOrders(updatedFilteredOrders);

      // If a shop is selected, filter orders by shop again
      if (selectedShop) {
        filterOrdersByShop(selectedShop);
      }
    } catch (error) {
      console.error("Error completing order:", error);
    }
  };

  //

  const handleShopSelect = (shopId) => {
    setSelectedShop(shopId);
    filterOrdersByShop(shopId);
    setIsShopListOpen(false);
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filtered = originalShops.filter((shop) =>
      shop.name.toLowerCase().includes(searchTerm)
    );
    setShops(filtered);
  };

  const filterOrdersByShop = (shopId) => {
    if (shopId === "all") {
      // If "Toate" (all) is selected, show all orders
      setFilteredOrders(orders);
    } else if (shopId) {
      // Filter orders by selected shopId
      const filtered = orders.filter((order) => order.shopId === shopId);
      setFilteredOrders(filtered);
    } else {
      // If no shop is selected, show all orders
      setFilteredOrders(orders);
    }
  };

  const filterOrdersByCompany = (company) => {
    let filtered = [...orders];

    if (company !== "all") {
      filtered = filtered.filter((order) => order.company === company);
    }

    if (selectedShop) {
      filtered = filtered.filter((order) => order.shopId === selectedShop);
    }

    setFilteredOrders(filtered);
  };

  const handleCompanyChange = (e) => {
    const company = e.target.value;
    setSelectedCompany(company);
    filterOrdersByCompany(company);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const groupedOrders = groupOrdersByDate(filteredOrders);

  return (
    <div className="operator">
      <h1>Comenzile</h1>
      {/* Company Selector */}
      <div>
        <label htmlFor="company-selector">Selectează Compania:</label>
        <select
          className="company-selector"
          value={selectedCompany}
          onChange={handleCompanyChange}
        >
          <option value="all">Toate</option>
          <option value="TriZooGrup">TriZooGrup</option>
          <option value="ZooKiWi">ZooKiWi</option>
        </select>
      </div>
      {/* Shop Selector */}
      <ShopSelector
        shops={shops}
        selectedShop={selectedShop}
        isShopListOpen={isShopListOpen}
        setIsShopListOpen={setIsShopListOpen}
        handleShopSelect={handleShopSelect}
        includeAllOption={true} // Enable the "Toate" option
      />
      <div className="operator_orders">
        {Object.keys(groupedOrders).map((date, index) => (
          <OrderGroup
            key={date}
            date={date}
            orders={groupedOrders[date]}
            isOpen={openAccordion === index}
            toggleAccordion={() => toggleAccordion(index)}
            handleCheckboxChange={handleCheckboxChange}
            handleCompleteOrder={handleCompleteOrder}
          />
        ))}
      </div>
      <button onClick={handleNavigateToHistory} className="operator-btn history-btn">
        Vezi Istoricul Comenzilor
      </button>
      <button onClick={handleNavigateToAddShop} className="operator-btn addshop-btn">
        Adaugă magazin
      </button>
    </div>
  );
}
export default Operator;
