import React from "react";

function Order({ order, handleCheckboxChange, handleCompleteOrder }) {
  const calculateTotalPrice = (products) => {
    return products.reduce(
      (total, product) => total + product.price * product.quantity,
      0
    );
  };

  return (
    <article className="operator_accordion">
      <h3>{order.shopName}</h3>
      <p className="order-date">
        Plasată pe data: {new Date(order.orderDate).toLocaleString()}
      </p>

      <p>Companie: {order.company}</p>
      <p>Factura: {order.factura ? "Da" : "Nu"}</p>
      <p>Comentariu: {order.comment ? order.comment : "-"}</p>

      <div className="operator_products">
        <h4>Produse:</h4>
        <ul>
          {order.products.map((product) => (
            <li key={product._id}>
              {product.name} - {product.quantity} unitați @ {product.price}{" "}
              lei/unitate
            </li>
          ))}
        </ul>
        <p className="order-total">
          Suma totală: {calculateTotalPrice(order.products)} lei
        </p>
      </div>
      <div className="operator_status">
        <label>
          <input
            type="checkbox"
            checked={order.delivered}
            onChange={() => handleCheckboxChange(order.orderId, "delivered")}
          />
          Livrat
        </label>
        <label>
          <input
            type="checkbox"
            checked={order.paid}
            onChange={() => handleCheckboxChange(order.orderId, "paid")}
          />
          Achitat
        </label>
      </div>
      <button
        className="complete-btn"
        disabled={!order.delivered || !order.paid}
        onClick={() => handleCompleteOrder(order.orderId)}
      >
        Completat
      </button>
    </article>
  );
}

export default Order;
