import React from "react";

const ShopSelector = ({
  shops,
  selectedShop,
  isShopListOpen,
  setIsShopListOpen,
  handleShopSelect,
  handleSearchChange,
  includeAllOption = false, // New prop to toggle "All" option
}) => {
  const displayedShops = includeAllOption
    ? [{ _id: "all", name: "Toate" }, ...shops] // Prepend "Toate" if the option is enabled
    : shops;

  return (
    <div className="agent_shop-selector">
      <div
        className="agent_shop-label"
        onClick={() => setIsShopListOpen(!isShopListOpen)}
      >
        {selectedShop
          ? `Magazinul Selectat: ${
              selectedShop === "all"
                ? "Toate"
                : shops.find((shop) => shop._id === selectedShop)?.name
            }`
          : "Selectează Magazin"}
        <button type="button" className="agent_toggle-btn">
          <img
            src="/images/icons/arrowdown.png"
            alt="\/"
            className={`agent_arrow ${
              isShopListOpen ? "agent_arrow--open" : ""
            }`}
          />
        </button>
      </div>
      <div
        className={`agent_accordion-container ${
          isShopListOpen ? "agent_accordion-container--open" : ""
        }`}
      >
        <div className="agent_accordion">
          <input
            type="text"
            placeholder="Search shops..."
            onChange={handleSearchChange}
            className="agent_search-input"
          />
          <div className="agent_shop-list">
            {displayedShops.map((shop) => (
              <div
                key={shop._id}
                className={`agent_shop-item ${
                  shop._id === selectedShop ? "agent_shop-item--selected" : ""
                }`}
                onClick={() => handleShopSelect(shop._id)} // Handle selection of shop
              >
                {shop.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopSelector;
