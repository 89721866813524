import React from "react";

function Comment({comment, setComment}) {
  return (
    <div className="agent_comment-section">
      <label>Comentariu:</label>
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="agent_comment-input"
      />
    </div>
  );
}

export default Comment;
