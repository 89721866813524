import React from "react";

function OrderCard({ order }) {
  const calculateTotalPrice = (products) => {
    return products.reduce(
      (total, product) => total + product.price * product.quantity,
      0
    );
  };

  return (
    <div className="history_order-card">
      <h2>{order.shopName}</h2>
      <p>Data comenzii: {new Date(order.orderDate).toLocaleString()}</p>
      <p>Compania: {order.company}</p>
      <p>Factura: {order.factura ? "Da" : "Nu"}</p>
      <p>Comentariu: {order.comment}</p>

      <div className="history_products">
        <h3>Produse:</h3>
        <ul>
          {order.products.map((product) => (
            <li key={product._id}>
              {product.name} - {product.quantity} unitați @ {product.price}{" "}
              lei/unitate
            </li>
          ))}
        </ul>
      </div>
      <p className="order-total">
        Suma totală: {calculateTotalPrice(order.products)} lei
      </p>
      <p>Livrat: {order.delivered ? "Da" : "Nu"}</p>
      <p>Plătit: {order.paid ? "Da" : "Nu"}</p>
      <p>Completat: {order.isCompleted ? "Da" : "Nu"}</p>
    </div>
  );
}

export default OrderCard;
