import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import ProductModal from "../../../General/Modal/ProductModal";
import { LOCALES, translate } from "../../../../i18n";
import { useSelector } from "react-redux";
import { productsRussian } from "../../../../data";

function Product(props) {
  const { product } = props;

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const productPageLink = `/product/${product._id}`;

  const [productLocal, setProductLocal] = useState(product);
  const language = useSelector((state) => state.language);
  useEffect(() => {
    const productRu = productsRussian.find((prod) => prod._id === product._id);

    if (language === LOCALES.RUSSIAN) {
      setProductLocal({
        ...product,
        name: productRu.name,
        description: productRu.description,
      });
    } else {
      setProductLocal(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <li className="product">
      <ProductModal modal={modal} setModal={setModal} product={product} />
      <article className="product_card">
        <div className="product_image">
          <Link to={productPageLink}>
            <img src={product.image} alt={productLocal.name} />
          </Link>
        </div>
        <section className="product_info">
          <div className="product_name">
            <Link to={productPageLink}>
              <h2>{productLocal.name}</h2>
            </Link>
          </div>
          <div className={product.nonSalePrice === 0 ? "price" : "price sale"}>
            <p>
              {product.nonSalePrice === 0 ? (
                ""
              ) : (
                <span className="nonSalePrice">{product.nonSalePrice} lei</span>
              )}
              {product.priceUnit}{" "}
              <span className="price_currency">{translate("lei")}</span>{" "}
              {product.complect > 1 && (
                <span className="price_onlyComplect">
                  ({translate("sellingOnly")} {product.complect}{" "}
                  {translate("unit")})
                </span>
              )}
            </p>
          </div>
          <div className="product_buttons">
            <Link to={productPageLink}>
              <button className="product_buttons_redirectButton">
                {translate("productPageButtonHome")}
              </button>
            </Link>
            <button
            disabled
              onClick={() => toggleModal()}
              className="product_buttons_cartButton button--disabled"
            >
              <FontAwesomeIcon icon={faCartPlus} />
            </button>
          </div>
        </section>
      </article>
    </li>
  );
}

export default Product;
