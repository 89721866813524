import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
// import HeaderContent from "./components/Header/HeaderContent";
import CartScreen from "./components/Main/Cart/CartScreen";
import MainContentScreen from "./components/Main/Home/MainContentScreen";
import ProductScreen from "./components/Main/Product/ProductScreen";
import ScrollToTop from "./helpers/components/ScrollToTop";

import { I18nProvider } from "./i18n";
// import Footer from "./components/Footer/Footer";
import DeliveryAndPayment from "./components/Main/DeliveryAndPayment/DeliveryAndPayment";
import Cats from "./components/Main/Products/Cats";
import Dogs from "./components/Main/Products/Dogs";
import Layout from "./Layout";
import AdminPanel from "./components/Main/AdminPanel/AdminPanel";
import AdminProducts from "./components/Main/AdminPanel/AdminProducts";
import Login from "./components/Main/AdminPanel/Login";
import Footer from "./components/Footer/Footer";
import HeaderContent from "./components/Header/HeaderContent";
import PrivateRoute from "./components/General/PrivateRoute";
import AdminCustomers from "./components/Main/AdminPanel/AdminCustomers";
import IonDulap from "./components/Main/AdminPanel/IonDulap";
import Operator from "./components/Main/Workers/Operator/Operator";
import Agent from "./components/Main/Workers/Agent/Agent";
import History from "./components/Main/Workers/History/History";
import Statistics from "./components/Main/Workers/Statistics/Statistics";
import AddShopForm from "./components/Main/Workers/AddShopForm/AddShopForm";
function App() {
  const language = useSelector((state) => state.language);

  return (
    <I18nProvider locale={language}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Layout>
                <MainContentScreen />
              </Layout>
            }
          />

          <Route
            exact
            path="/cart"
            element={
              <Layout>
                <CartScreen />
              </Layout>
            }
          />
          <Route
            exact
            path="/product/:id"
            element={
              <Layout>
                <ProductScreen />
              </Layout>
            }
          />
          <Route
            exact
            path="/iondulap"
            element={
              <PrivateRoute roles={["admin", "manager"]}>
                <IonDulap />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/agent"
            element={
              <PrivateRoute roles={["admin", "agent"]}>
                <Agent />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/operator"
            element={
              <PrivateRoute roles={["admin", "operator"]}>
                <Operator />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/history"
            element={
              <PrivateRoute roles={["admin", "operator", "agent"]}>
                <History />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/statistics"
            element={
              <PrivateRoute roles={["admin", "operator"]}>
                <Statistics />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/addshop"
            element={
              <PrivateRoute roles={["admin", "operator"]}>
                <AddShopForm />
              </PrivateRoute>
            }
          />

          <Route exact path="/loginacc" element={<Login />} />
          <Route
            exact
            path="/delivery-and-payment"
            element={
              <Layout>
                <DeliveryAndPayment />
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </I18nProvider>
  );
}

export default App;

/* <Route
            exact
            path="/listings/cats"
            element={
              <Layout>
                <Cats />
              </Layout>
            }
          /> */
/* <Route
            exact
            path="/listings/dogs"
            element={
              <Layout>
                <Dogs />
              </Layout>
            }
          /> */

/* <Route
            exact
            path="/aS4damN4id"
            element={
              <PrivateRoute roles={["admin", "manager"]}>
                <AdminPanel />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/aS4damN4id/orders"
            element={
              <PrivateRoute roles={["admin", "manager"]}>
                <AdminPanel />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/aS4damN4id/products"
            element={
              <PrivateRoute roles={["admin", "manager"]}>
                <AdminProducts />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/aS4damN4id/customers"
            element={
              <PrivateRoute roles={["admin", "manager"]}>
                <AdminCustomers />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/aS4damN4id/statistics"
            element={
              <PrivateRoute roles={["admin", "manager"]}>
                <AdminPanel />
              </PrivateRoute>
            }
          /> */
